// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { isNil, get } from 'lodash'

export default {
  mixins: [uiMixin],
  computed: {
    ...mapGetters('authentication', ['onboardingSections', 'onboardingCompleted', 'userData'])
  },
  watch: {
    onboardingSections: {
      handler(value) {
        if (value && value.length && this.userData && this.userData.id) {
          const currentSection = value.find((section) => section.current)
          if (currentSection) {
            const url = `/onboarding/${currentSection.id}`
            const params = !isNil(currentSection.params)
              ? `/${Object.values(currentSection.params).join('/')}`
              : ''
            if (this.$route.path.indexOf(url) === -1) {
              this.routerPushTo(`${url}${params}`)
            }
          }
        }
      },
      immediate: true
    },
    onboardingCompleted: {
      handler(value) {
        if (value && this.userData && this.userData.id) {
          this.routerPushTo({
            path: '/onboarding/finish'
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', ['updateUser']),
    /**
     * Establecemos paso como completado
     *
     * @param {string} section - sección a marcar como completada
     */
    async setSectionCompleted(section = null) {
      if (isNil(section)) {
        return
      }

      try {
        // Loading
        this.modifyAppLoadingLayer({ visible: true, translucid: true })
        // Cambiamos estado
        const onboarding = get(this.userData, 'onboarding', {})
        await this.updateUser({
          id: this.userData.id,
          onboarding: { ...onboarding, [section]: true }
        })
      } finally {
        // loading
        this.modifyAppLoadingLayer({ visible: false })
      }
    }
  }
}
